/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

[type="checkbox"],
[type="radio"] {
  height: 2rem;
  width: 2rem;
}

@font-face {
  font-family: "Buffalo";
  src: url("/fonts/Buffalo.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("/fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;

}

html,
body,
#__next {
  height: 100%;
}

.hylite-gradient {
  @apply bg-gradient-to-r;
  @apply from-primary-500;
  @apply via-primary-400;
  @apply to-primary-400;
}

@media print {
  html,
  body {
    height: auto;
  }
  #__next {
    height: 100%;
  }
}

.break-after {
  break-after: page;
}

.a4 {
  /* original - height: 21cm; */
  height: 19cm;
  width: 29.7cm;
  overflow: hidden;
  /* margin: 30mm 45mm 30mm 45mm; */
  /* change the margins as you want them to be. */
}

.letter-paper {
  /* original - height: 21cm; */
  height: 8.5in;
  width: 11in;
  overflow: hidden;
  /* margin: 30mm 45mm 30mm 45mm; */
  /* change the margins as you want them to be. */
}

/* ./styles/globals.css */
[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::placeholder,textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
          print-color-adjust: unset;
}
[type='checkbox'],[type='radio'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
[type='checkbox'] {
  border-radius: 0px;
}
[type='radio'] {
  border-radius: 100%;
}
[type='checkbox']:focus,[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}
[type='checkbox']:checked,[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type='file']:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}
.translate-y-4, .translate-y-0, .scale-95, .scale-100, .transform, .\!transform, .sm\:translate-y-0, .sm\:scale-95, .sm\:scale-100 {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.shadow-lg, .shadow-sm, .shadow-xl, .shadow {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}
.ring-1, .focus-within\:ring-1, .focus\:ring-0, .focus\:ring-2, .focus\:ring-1 {
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}
.blur, .filter {
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
#__next .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
#__next .pointer-events-none {
  pointer-events: none;
}
#__next .visible {
  visibility: visible;
}
#__next .collapse {
  visibility: collapse;
}
#__next .static {
  position: static;
}
#__next .fixed {
  position: fixed;
}
#__next .absolute {
  position: absolute;
}
#__next .relative {
  position: relative;
}
#__next .sticky {
  position: sticky;
}
#__next .inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
#__next .inset-y-0 {
  top: 0px;
  bottom: 0px;
}
#__next .top-2 {
  top: 0.5rem;
}
#__next .right-2 {
  right: 0.5rem;
}
#__next .top-0 {
  top: 0px;
}
#__next .left-0 {
  left: 0px;
}
#__next .bottom-0 {
  bottom: 0px;
}
#__next .right-0 {
  right: 0px;
}
#__next .-top-2 {
  top: -0.5rem;
}
#__next .left-2 {
  left: 0.5rem;
}
#__next .top-1 {
  top: 0.25rem;
}
#__next .z-50 {
  z-index: 50;
}
#__next .z-10 {
  z-index: 10;
}
#__next .z-20 {
  z-index: 20;
}
#__next .z-30 {
  z-index: 30;
}
#__next .col-span-12 {
  grid-column: span 12 / span 12;
}
#__next .col-span-6 {
  grid-column: span 6 / span 6;
}
#__next .col-span-5 {
  grid-column: span 5 / span 5;
}
#__next .col-span-4 {
  grid-column: span 4 / span 4;
}
#__next .float-right {
  float: right;
}
#__next .m-4 {
  margin: 1rem;
}
#__next .m-5 {
  margin: 1.25rem;
}
#__next .m-20 {
  margin: 5rem;
}
#__next .m-auto {
  margin: auto;
}
#__next .m-1 {
  margin: 0.25rem;
}
#__next .m-8 {
  margin: 2rem;
}
#__next .m-10 {
  margin: 2.5rem;
}
#__next .my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#__next .my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
#__next .mx-auto {
  margin-left: auto;
  margin-right: auto;
}
#__next .mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
#__next .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#__next .my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
#__next .my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
#__next .my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#__next .my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
#__next .my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
#__next .-mt-10 {
  margin-top: -2.5rem;
}
#__next .-mb-10 {
  margin-bottom: -2.5rem;
}
#__next .mt-4 {
  margin-top: 1rem;
}
#__next .mt-6 {
  margin-top: 1.5rem;
}
#__next .mb-10 {
  margin-bottom: 2.5rem;
}
#__next .mt-1 {
  margin-top: 0.25rem;
}
#__next .mt-2 {
  margin-top: 0.5rem;
}
#__next .mt-5 {
  margin-top: 1.25rem;
}
#__next .mt-10 {
  margin-top: 2.5rem;
}
#__next .ml-0 {
  margin-left: 0px;
}
#__next .ml-5 {
  margin-left: 1.25rem;
}
#__next .mr-5 {
  margin-right: 1.25rem;
}
#__next .mr-2 {
  margin-right: 0.5rem;
}
#__next .ml-10 {
  margin-left: 2.5rem;
}
#__next .mb-20 {
  margin-bottom: 5rem;
}
#__next .mt-20 {
  margin-top: 5rem;
}
#__next .mb-4 {
  margin-bottom: 1rem;
}
#__next .mt-16 {
  margin-top: 4rem;
}
#__next .mb-3 {
  margin-bottom: 0.75rem;
}
#__next .mt-0 {
  margin-top: 0px;
}
#__next .mb-6 {
  margin-bottom: 1.5rem;
}
#__next .mr-4 {
  margin-right: 1rem;
}
#__next .-mt-20 {
  margin-top: -5rem;
}
#__next .mt-3 {
  margin-top: 0.75rem;
}
#__next .mr-3 {
  margin-right: 0.75rem;
}
#__next .-mt-60 {
  margin-top: -15rem;
}
#__next .mb-5 {
  margin-bottom: 1.25rem;
}
#__next .mb-8 {
  margin-bottom: 2rem;
}
#__next .ml-3 {
  margin-left: 0.75rem;
}
#__next .ml-6 {
  margin-left: 1.5rem;
}
#__next .mr-10 {
  margin-right: 2.5rem;
}
#__next .mt-12 {
  margin-top: 3rem;
}
#__next .ml-2 {
  margin-left: 0.5rem;
}
#__next .mb-2 {
  margin-bottom: 0.5rem;
}
#__next .mr-1 {
  margin-right: 0.25rem;
}
#__next .ml-1 {
  margin-left: 0.25rem;
}
#__next .mt-7 {
  margin-top: 1.75rem;
}
#__next .-mt-3 {
  margin-top: -0.75rem;
}
#__next .-mb-4 {
  margin-bottom: -1rem;
}
#__next .-mt-16 {
  margin-top: -4rem;
}
#__next .-mb-16 {
  margin-bottom: -4rem;
}
#__next .-mt-32 {
  margin-top: -8rem;
}
#__next .-ml-1 {
  margin-left: -0.25rem;
}
#__next .mt-8 {
  margin-top: 2rem;
}
#__next .-mb-px {
  margin-bottom: -1px;
}
#__next .mb-1 {
  margin-bottom: 0.25rem;
}
#__next .-mt-px {
  margin-top: -1px;
}
#__next .ml-px {
  margin-left: 1px;
}
#__next .-ml-px {
  margin-left: -1px;
}
#__next .-mr-2 {
  margin-right: -0.5rem;
}
#__next .ml-auto {
  margin-left: auto;
}
#__next .mb-0 {
  margin-bottom: 0px;
}
#__next .block {
  display: block;
}
#__next .inline-block {
  display: inline-block;
}
#__next .inline {
  display: inline;
}
#__next .flex {
  display: flex;
}
#__next .inline-flex {
  display: inline-flex;
}
#__next .table {
  display: table;
}
#__next .grid {
  display: grid;
}
#__next .contents {
  display: contents;
}
#__next .hidden {
  display: none;
}
#__next .h-screen {
  height: 100vh;
}
#__next .h-full {
  height: 100%;
}
#__next .h-20 {
  height: 5rem;
}
#__next .h-12 {
  height: 3rem;
}
#__next .h-6 {
  height: 1.5rem;
}
#__next .h-5 {
  height: 1.25rem;
}
#__next .h-11 {
  height: 2.75rem;
}
#__next .h-96 {
  height: 24rem;
}
#__next .h-7 {
  height: 1.75rem;
}
#__next .h-64 {
  height: 16rem;
}
#__next .h-52 {
  height: 13rem;
}
#__next .h-72 {
  height: 18rem;
}
#__next .h-1\/4 {
  height: 25%;
}
#__next .h-10 {
  height: 2.5rem;
}
#__next .h-4 {
  height: 1rem;
}
#__next .h-16 {
  height: 4rem;
}
#__next .h-8 {
  height: 2rem;
}
#__next .h-2\/3 {
  height: 66.666667%;
}
#__next .h-\[121px\] {
  height: 121px;
}
#__next .h-48 {
  height: 12rem;
}
#__next .max-h-60 {
  max-height: 15rem;
}
#__next .min-h-full {
  min-height: 100%;
}
#__next .min-h-screen {
  min-height: 100vh;
}
#__next .w-screen {
  width: 100vw;
}
#__next .w-full {
  width: 100%;
}
#__next .w-48 {
  width: 12rem;
}
#__next .w-56 {
  width: 14rem;
}
#__next .w-80 {
  width: 20rem;
}
#__next .w-12 {
  width: 3rem;
}
#__next .w-6 {
  width: 1.5rem;
}
#__next .w-5 {
  width: 1.25rem;
}
#__next .w-11 {
  width: 2.75rem;
}
#__next .w-7 {
  width: 1.75rem;
}
#__next .w-20 {
  width: 5rem;
}
#__next .w-0 {
  width: 0px;
}
#__next .w-44 {
  width: 11rem;
}
#__next .w-auto {
  width: auto;
}
#__next .w-7\/12 {
  width: 58.333333%;
}
#__next .w-4\/6 {
  width: 66.666667%;
}
#__next .w-10 {
  width: 2.5rem;
}
#__next .w-32 {
  width: 8rem;
}
#__next .w-4 {
  width: 1rem;
}
#__next .w-64 {
  width: 16rem;
}
#__next .w-60 {
  width: 15rem;
}
#__next .w-40 {
  width: 10rem;
}
#__next .w-28 {
  width: 7rem;
}
#__next .w-8 {
  width: 2rem;
}
#__next .w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
#__next .w-\[100px\] {
  width: 100px;
}
#__next .w-1\/2 {
  width: 50%;
}
#__next .min-w-\[260px\] {
  min-width: 260px;
}
#__next .min-w-0 {
  min-width: 0px;
}
#__next .max-w-4xl {
  max-width: 56rem;
}
#__next .max-w-6xl {
  max-width: 72rem;
}
#__next .max-w-xl {
  max-width: 36rem;
}
#__next .max-w-lg {
  max-width: 32rem;
}
#__next .max-w-full {
  max-width: 100%;
}
#__next .max-w-sm {
  max-width: 24rem;
}
#__next .max-w-7xl {
  max-width: 80rem;
}
#__next .max-w-xs {
  max-width: 20rem;
}
#__next .flex-1 {
  flex: 1 1 0%;
}
#__next .flex-shrink-0 {
  flex-shrink: 0;
}
#__next .flex-grow {
  flex-grow: 1;
}
#__next .grow {
  flex-grow: 1;
}
#__next .basis-full {
  flex-basis: 100%;
}
#__next .table-fixed {
  table-layout: fixed;
}
#__next .border-collapse {
  border-collapse: collapse;
}
#__next .origin-top-right {
  transform-origin: top right;
}
#__next .translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#__next .translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#__next .scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#__next .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#__next .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#__next .\!transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
#__next .animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes pulse {
  50% {
    opacity: .5;
  }
}
#__next .animate-pulse-once {
  animation: pulse 2s linear 1;
}
#__next .cursor-pointer {
  cursor: pointer;
}
#__next .cursor-default {
  cursor: default;
}
#__next .select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#__next .resize {
  resize: both;
}
#__next .list-disc {
  list-style-type: disc;
}
#__next .list-decimal {
  list-style-type: decimal;
}
#__next .appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
#__next .grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
#__next .grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
#__next .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
#__next .grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
#__next .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
#__next .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
#__next .flex-row {
  flex-direction: row;
}
#__next .flex-col {
  flex-direction: column;
}
#__next .flex-wrap {
  flex-wrap: wrap;
}
#__next .content-center {
  align-content: center;
}
#__next .items-end {
  align-items: flex-end;
}
#__next .items-center {
  align-items: center;
}
#__next .justify-start {
  justify-content: flex-start;
}
#__next .justify-end {
  justify-content: flex-end;
}
#__next .justify-center {
  justify-content: center;
}
#__next .justify-between {
  justify-content: space-between;
}
#__next .justify-around {
  justify-content: space-around;
}
#__next .justify-items-center {
  justify-items: center;
}
#__next .gap-8 {
  gap: 2rem;
}
#__next .gap-4 {
  gap: 1rem;
}
#__next .gap-5 {
  gap: 1.25rem;
}
#__next .gap-3 {
  gap: 0.75rem;
}
#__next .gap-1 {
  gap: 0.25rem;
}
#__next .gap-x-1 {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}
#__next .gap-y-2 {
  row-gap: 0.5rem;
}
#__next .gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
#__next .gap-y-6 {
  row-gap: 1.5rem;
}
#__next .gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
#__next .space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
#__next .space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
#__next .space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
#__next .space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
#__next .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
#__next .space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
#__next .space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
#__next .divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
#__next .divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}
#__next .place-self-end {
  place-self: end;
}
#__next .justify-self-start {
  justify-self: start;
}
#__next .justify-self-end {
  justify-self: end;
}
#__next .overflow-auto {
  overflow: auto;
}
#__next .overflow-hidden {
  overflow: hidden;
}
#__next .overflow-visible {
  overflow: visible;
}
#__next .overflow-x-auto {
  overflow-x: auto;
}
#__next .overflow-y-auto {
  overflow-y: auto;
}
#__next .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#__next .whitespace-nowrap {
  white-space: nowrap;
}
#__next .whitespace-pre-line {
  white-space: pre-line;
}
#__next .whitespace-pre-wrap {
  white-space: pre-wrap;
}
#__next .break-words {
  overflow-wrap: break-word;
}
#__next .rounded-sm {
  border-radius: 0.125rem;
}
#__next .rounded-lg {
  border-radius: 0.5rem;
}
#__next .rounded-full {
  border-radius: 9999px;
}
#__next .rounded-md {
  border-radius: 0.375rem;
}
#__next .rounded-2xl {
  border-radius: 1rem;
}
#__next .rounded-3xl {
  border-radius: 1.5rem;
}
#__next .rounded-xl {
  border-radius: 0.75rem;
}
#__next .rounded {
  border-radius: 0.25rem;
}
#__next .rounded-none {
  border-radius: 0px;
}
#__next .rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#__next .rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
#__next .rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
#__next .rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
#__next .rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
#__next .rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
#__next .rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
#__next .rounded-bl-none {
  border-bottom-left-radius: 0px;
}
#__next .rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}
#__next .border {
  border-width: 1px;
}
#__next .border-2 {
  border-width: 2px;
}
#__next .border-4 {
  border-width: 4px;
}
#__next .border-0 {
  border-width: 0px;
}
#__next .border-r-4 {
  border-right-width: 4px;
}
#__next .border-l-4 {
  border-left-width: 4px;
}
#__next .border-t-4 {
  border-top-width: 4px;
}
#__next .border-b-4 {
  border-bottom-width: 4px;
}
#__next .border-b {
  border-bottom-width: 1px;
}
#__next .border-t {
  border-top-width: 1px;
}
#__next .border-b-2 {
  border-bottom-width: 2px;
}
#__next .border-r-0 {
  border-right-width: 0px;
}
#__next .border-solid {
  border-style: solid;
}
#__next .border-none {
  border-style: none;
}
#__next .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
#__next .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#__next .border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
#__next .border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}
#__next .border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}
#__next .border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
#__next .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
#__next .border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}
#__next .border-transparent {
  border-color: transparent;
}
#__next .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
#__next .border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
#__next .border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}
#__next .border-opacity-5 {
  --tw-border-opacity: 0.05;
}
#__next .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
#__next .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
#__next .bg-transparent {
  background-color: transparent;
}
#__next .bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
#__next .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
#__next .bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
#__next .bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
#__next .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
#__next .bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
#__next .bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}
#__next .bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
#__next .bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
#__next .bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}
#__next .bg-primary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
#__next .bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
#__next .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
#__next .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
#__next .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
#__next .bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
#__next .bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}
#__next .bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
#__next .bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}
#__next .bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
#__next .bg-opacity-100 {
  --tw-bg-opacity: 1;
}
#__next .bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
#__next .bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
#__next .bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
#__next .bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
#__next .bg-banner {
  background-image: url(/_next/static/media/Edits-for-Hylites.480a502e.jpg);
}
#__next .from-red-500 {
  --tw-gradient-from: #ef4444;
  --tw-gradient-to: rgb(239 68 68 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
#__next .from-red-400 {
  --tw-gradient-from: #f87171;
  --tw-gradient-to: rgb(248 113 113 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
#__next .from-pink-600 {
  --tw-gradient-from: #EE106C;
  --tw-gradient-to: rgb(238 16 108 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
#__next .from-red-600 {
  --tw-gradient-from: #dc2626;
  --tw-gradient-to: rgb(220 38 38 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
#__next .from-red-200 {
  --tw-gradient-from: #fecaca;
  --tw-gradient-to: rgb(254 202 202 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
#__next .via-red-400 {
  --tw-gradient-to: rgb(248 113 113 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to);
}
#__next .via-orange-600 {
  --tw-gradient-to: rgb(234 88 12 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), #ea580c, var(--tw-gradient-to);
}
#__next .via-red-100 {
  --tw-gradient-to: rgb(254 226 226 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to);
}
#__next .to-red-400 {
  --tw-gradient-to: #f87171;
}
#__next .to-orange-400 {
  --tw-gradient-to: #fb923c;
}
#__next .to-orange-300 {
  --tw-gradient-to: #fdba74;
}
#__next .to-red-200 {
  --tw-gradient-to: #fecaca;
}
#__next .to-gold-600 {
  --tw-gradient-to: #FA8A49;
}
#__next .bg-cover {
  background-size: cover;
}
#__next .bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}
#__next .bg-center {
  background-position: center;
}
#__next .bg-no-repeat {
  background-repeat: no-repeat;
}
#__next .object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}
#__next .p-2 {
  padding: 0.5rem;
}
#__next .p-16 {
  padding: 4rem;
}
#__next .p-5 {
  padding: 1.25rem;
}
#__next .p-6 {
  padding: 1.5rem;
}
#__next .p-1 {
  padding: 0.25rem;
}
#__next .p-4 {
  padding: 1rem;
}
#__next .p-10 {
  padding: 2.5rem;
}
#__next .p-8 {
  padding: 2rem;
}
#__next .p-2\.5 {
  padding: 0.625rem;
}
#__next .p-0 {
  padding: 0px;
}
#__next .p-3 {
  padding: 0.75rem;
}
#__next .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
#__next .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
#__next .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
#__next .px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}
#__next .px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
#__next .py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
#__next .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
#__next .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#__next .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
#__next .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#__next .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#__next .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
#__next .px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}
#__next .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
#__next .px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
#__next .py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
#__next .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
#__next .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#__next .py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
#__next .py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
#__next .pb-10 {
  padding-bottom: 2.5rem;
}
#__next .pb-16 {
  padding-bottom: 4rem;
}
#__next .pt-4 {
  padding-top: 1rem;
}
#__next .pb-0 {
  padding-bottom: 0px;
}
#__next .pl-5 {
  padding-left: 1.25rem;
}
#__next .pr-5 {
  padding-right: 1.25rem;
}
#__next .pb-32 {
  padding-bottom: 8rem;
}
#__next .pt-20 {
  padding-top: 5rem;
}
#__next .pb-12 {
  padding-bottom: 3rem;
}
#__next .pb-20 {
  padding-bottom: 5rem;
}
#__next .pt-6 {
  padding-top: 1.5rem;
}
#__next .pt-10 {
  padding-top: 2.5rem;
}
#__next .pb-5 {
  padding-bottom: 1.25rem;
}
#__next .pt-14 {
  padding-top: 3.5rem;
}
#__next .pb-2 {
  padding-bottom: 0.5rem;
}
#__next .pt-5 {
  padding-top: 1.25rem;
}
#__next .pb-4 {
  padding-bottom: 1rem;
}
#__next .pb-40 {
  padding-bottom: 10rem;
}
#__next .pl-6 {
  padding-left: 1.5rem;
}
#__next .pl-3 {
  padding-left: 0.75rem;
}
#__next .pb-24 {
  padding-bottom: 6rem;
}
#__next .pl-10 {
  padding-left: 2.5rem;
}
#__next .pr-10 {
  padding-right: 2.5rem;
}
#__next .pr-2 {
  padding-right: 0.5rem;
}
#__next .pr-9 {
  padding-right: 2.25rem;
}
#__next .pr-4 {
  padding-right: 1rem;
}
#__next .pl-1 {
  padding-left: 0.25rem;
}
#__next .pr-1 {
  padding-right: 0.25rem;
}
#__next .pl-16 {
  padding-left: 4rem;
}
#__next .pl-4 {
  padding-left: 1rem;
}
#__next .pr-3 {
  padding-right: 0.75rem;
}
#__next .pr-1\.5 {
  padding-right: 0.375rem;
}
#__next .pt-1 {
  padding-top: 0.25rem;
}
#__next .pt-2 {
  padding-top: 0.5rem;
}
#__next .pb-3 {
  padding-bottom: 0.75rem;
}
#__next .text-left {
  text-align: left;
}
#__next .text-center {
  text-align: center;
}
#__next .text-right {
  text-align: right;
}
#__next .align-middle {
  vertical-align: middle;
}
#__next .align-bottom {
  vertical-align: bottom;
}
#__next .align-text-top {
  vertical-align: text-top;
}
#__next .align-text-bottom {
  vertical-align: text-bottom;
}
#__next .align-sub {
  vertical-align: sub;
}
#__next .font-sans {
  font-family: Evolve Sans;
}
#__next .font-sans2 {
  font-family: Bebas Neue;
}
#__next .font-cursive {
  font-family: Buffalo;
}
#__next .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
#__next .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
#__next .text-5xl {
  font-size: 3rem;
  line-height: 1;
}
#__next .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
#__next .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
#__next .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
#__next .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
#__next .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
#__next .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
#__next .text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
#__next .text-9xl {
  font-size: 8rem;
  line-height: 1;
}
#__next .text-8xl {
  font-size: 6rem;
  line-height: 1;
}
#__next .font-bold {
  font-weight: 700;
}
#__next .font-thin {
  font-weight: 100;
}
#__next .font-medium {
  font-weight: 500;
}
#__next .font-semibold {
  font-weight: 600;
}
#__next .font-normal {
  font-weight: 400;
}
#__next .font-extrabold {
  font-weight: 800;
}
#__next .uppercase {
  text-transform: uppercase;
}
#__next .leading-6 {
  line-height: 1.5rem;
}
#__next .leading-7 {
  line-height: 1.75rem;
}
#__next .leading-4 {
  line-height: 1rem;
}
#__next .leading-tight {
  line-height: 1.25;
}
#__next .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#__next .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
#__next .text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}
#__next .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
#__next .text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}
#__next .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
#__next .text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}
#__next .text-transparent {
  color: transparent;
}
#__next .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
#__next .text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#__next .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
#__next .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
#__next .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
#__next .text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}
#__next .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
#__next .text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}
#__next .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
#__next .underline {
  text-decoration-line: underline;
}
#__next .no-underline {
  text-decoration-line: none;
}
#__next .placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
#__next .placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
#__next .placeholder-red-300::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}
#__next .placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}
#__next .placeholder-gray-500::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
#__next .placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
#__next .opacity-25 {
  opacity: 0.25;
}
#__next .opacity-75 {
  opacity: 0.75;
}
#__next .opacity-30 {
  opacity: 0.3;
}
#__next .opacity-0 {
  opacity: 0;
}
#__next .opacity-100 {
  opacity: 1;
}
#__next .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#__next .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#__next .shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#__next .shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#__next .outline {
  outline-style: solid;
}
#__next .outline-1 {
  outline-width: 1px;
}
#__next .outline-defaultOutline {
  outline-color: rgba(0, 0, 0, 0.23);
}
#__next .ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
#__next .ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
#__next .ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
#__next .ring-offset-2 {
  --tw-ring-offset-width: 2px;
}
#__next .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
#__next .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
#__next .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
#__next .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
#__next .transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
#__next .duration-100 {
  transition-duration: 100ms;
}
#__next .duration-75 {
  transition-duration: 75ms;
}
#__next .duration-300 {
  transition-duration: 300ms;
}
#__next .duration-200 {
  transition-duration: 200ms;
}
#__next .ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
#__next .ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

[type="checkbox"],
[type="radio"] {
  height: 2rem;
  width: 2rem;
}

@font-face {
  font-family: "Buffalo";
  src: url("/fonts/Buffalo.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("/fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;

}

html,
body,
#__next {
  height: 100%;
}

.hylite-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #ef4444;
  --tw-gradient-to: rgb(239 68 68 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(248 113 113 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to);
  --tw-gradient-to: #f87171;
}

@media print {
  html,
  body {
    height: auto;
  }
  #__next {
    height: 100%;
  }
}

.break-after {
  -moz-column-break-after: page;
       break-after: page;
}

.a4 {
  /* original - height: 21cm; */
  height: 19cm;
  width: 29.7cm;
  overflow: hidden;
  /* margin: 30mm 45mm 30mm 45mm; */
  /* change the margins as you want them to be. */
}

.letter-paper {
  /* original - height: 21cm; */
  height: 8.5in;
  width: 11in;
  overflow: hidden;
  /* margin: 30mm 45mm 30mm 45mm; */
  /* change the margins as you want them to be. */
}

#__next .focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

#__next .focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#__next .focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}

#__next .hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

#__next .hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

#__next .hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#__next .hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

#__next .hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

#__next .hover\:bg-primary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

#__next .hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

#__next .hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

#__next .hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

#__next .hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

#__next .hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}

#__next .hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

#__next .hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

#__next .hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

#__next .hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

#__next .hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

#__next .hover\:underline:hover {
  text-decoration-line: underline;
}

#__next .focus\:border-none:focus {
  border-style: none;
}

#__next .focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

#__next .focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

#__next .focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

#__next .focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#__next .focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#__next .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#__next .focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#__next .focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

#__next .focus\:ring-primary-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

#__next .focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

#__next .focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

#__next .focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

#__next .focus\:ring-offset-gray-100:focus {
  --tw-ring-offset-color: #f3f4f6;
}

@media (min-width: 640px) {
  #__next .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  #__next .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  #__next .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  #__next .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  #__next .sm\:mt-0 {
    margin-top: 0px;
  }
  #__next .sm\:ml-4 {
    margin-left: 1rem;
  }
  #__next .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  #__next .sm\:ml-6 {
    margin-left: 1.5rem;
  }
  #__next .sm\:block {
    display: block;
  }
  #__next .sm\:inline-block {
    display: inline-block;
  }
  #__next .sm\:flex {
    display: flex;
  }
  #__next .sm\:hidden {
    display: none;
  }
  #__next .sm\:h-screen {
    height: 100vh;
  }
  #__next .sm\:h-10 {
    height: 2.5rem;
  }
  #__next .sm\:w-9\/12 {
    width: 75%;
  }
  #__next .sm\:w-56 {
    width: 14rem;
  }
  #__next .sm\:w-full {
    width: 100%;
  }
  #__next .sm\:w-10 {
    width: 2.5rem;
  }
  #__next .sm\:w-auto {
    width: auto;
  }
  #__next .sm\:max-w-lg {
    max-width: 32rem;
  }
  #__next .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #__next .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #__next .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #__next .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #__next .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  #__next .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  #__next .sm\:items-start {
    align-items: flex-start;
  }
  #__next .sm\:items-center {
    align-items: center;
  }
  #__next .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  #__next .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #__next .sm\:p-6 {
    padding: 1.5rem;
  }
  #__next .sm\:p-0 {
    padding: 0px;
  }
  #__next .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  #__next .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  #__next .sm\:pb-4 {
    padding-bottom: 1rem;
  }
  #__next .sm\:pt-0 {
    padding-top: 0px;
  }
  #__next .sm\:pl-10 {
    padding-left: 2.5rem;
  }
  #__next .sm\:pl-14 {
    padding-left: 3.5rem;
  }
  #__next .sm\:text-left {
    text-align: left;
  }
  #__next .sm\:align-middle {
    vertical-align: middle;
  }
  #__next .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  #__next .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 768px) {
  #__next .md\:mt-0 {
    margin-top: 0px;
  }
  #__next .md\:ml-4 {
    margin-left: 1rem;
  }
  #__next .md\:inline-block {
    display: inline-block;
  }
  #__next .md\:flex {
    display: flex;
  }
  #__next .md\:items-center {
    align-items: center;
  }
  #__next .md\:justify-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  #__next .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  #__next .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  #__next .lg\:col-start-4 {
    grid-column-start: 4;
  }
  #__next .lg\:col-start-5 {
    grid-column-start: 5;
  }
  #__next .lg\:block {
    display: block;
  }
  #__next .lg\:hidden {
    display: none;
  }
  #__next .lg\:w-96 {
    width: 24rem;
  }
  #__next .lg\:w-2\/3 {
    width: 66.666667%;
  }
  #__next .lg\:flex-none {
    flex: none;
  }
  #__next .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  #__next .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  #__next .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  #__next .xl\:pt-24 {
    padding-top: 6rem;
  }
}

@media (min-width: 1536px) {
  #__next .\32xl\:pt-52 {
    padding-top: 13rem;
  }
}

.iphone-x {
  position: relative;

  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
}

.iphone-x:before,
.iphone-x:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}

.iphone-x .iphone-camera,
.iphone-x .iphone-speaker {
  position: absolute;
  display: block;
  color: transparent;
}

.iphone-x .iphone-camera {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}

.iphone-x .iphone-speaker {
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
}

.iphone-x .iphone-camera:after {
  content: "";
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}

.text-height {
  transform: scale(1, 1.5);
}

@media screen and (max-width: 1118px) {
  .text-height {
    transform: scaleX(0.6);
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  flex: 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
@font-face {
font-family: '__evolve_cdc280';
src: url(/_next/static/media/1fac993c81d12f5f.p.otf) format('opentype');
font-display: optional;
font-weight: 400;
}

@font-face {
font-family: '__evolve_cdc280';
src: url(/_next/static/media/60007016a6fb97e2.p.otf) format('opentype');
font-display: optional;
font-weight: 500;
}@font-face {font-family: '__evolve_Fallback_cdc280';src: local("Arial");ascent-override: 96.19%;descent-override: 24.15%;line-gap-override: 2.78%;size-adjust: 93.56%
}.__className_cdc280 {font-family: '__evolve_cdc280', '__evolve_Fallback_cdc280'
}

